<template>
  <div class="plan-list plans-list">
    <van-nav-bar left-arrow title="改善排查计划" @click-left="$router.back()" />
    <van-search
      v-model="queryInfo.name"
      class="search-bar"
      placeholder="请输入计划名字关键字"
      @clear="onRefresh"
      @search="onRefresh"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <div class="search-query">
      <plan-list-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条排查计划</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="plan-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="['plan-list__rows', idx === list.length - 1 && 'border-none']"
          :border="false"
          @click="gotoDetails(item)"
        >
          <p class="title">{{ item.name }}</p>
          <div class="intro">
            <div class="field field-left">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="bumen"
              />
              <span>{{ item.orgLevelName }}</span>
            </div>
            <div class="field field-right">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="bumen"
              />
              <span>{{ item.checkTypeName }}</span>
            </div>
          </div>
          <div class="time">
            <div class="field field-center">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="ico-timerange"
              />
              <span>计划时间：{{ item.startDate }}至{{ item.endDate }}</span>
            </div>
            <div class="right">您已提交{{ item.planCheckCount }}次</div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getPagePlans } from "@/api/improve/improve";
import "./styles/list.scss";
import PlanListQuery from "@/views/plans/components/PlanListQuery";

export default {
  name: "PlanList",
  components: { PlanListQuery },
  data() {
    return {
      queryInfo: {
        name: "",
        status: "0,1,2", // 计划状态
        page: 1,
        size: 10
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  created() {
    // this.onRefresh();
  },
  activated() {
    console.log("activated");
    this.onRefresh();
  },
  methods: {
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const { list, total } = await getPagePlans(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails(row) {
      this.$router.push({ name: "ImprovePlanDetail", params: { id: row.id } });
    }
  }
};
</script>

<style lang="scss">
.plans-list {
  .plan-list__rows {
    .field-left {
      width: 80px;
      margin-right: 10px;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .field-right {
      width: calc(100% - 90px);
      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
